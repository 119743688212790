import { mapActions } from 'vuex'
import  { myMixin } from '../../../mixins';

export default{
  mixins: [myMixin],
  data: () => ({
        loading: false,
        loadingProgress: false,
        hours: [
          { id: 1,  start: '07:00', end: '08:00', shift_id: 1 },
          { id: 2,  start: '08:00', end: '09:00', shift_id: 1 },
          { id: 3,  start: '09:00', end: '10:00', shift_id: 1 },
          { id: 4,  start: '10:00', end: '11:00', shift_id: 1 },
          { id: 5,  start: '11:00', end: '12:00', shift_id: 1 },
          { id: 6,  start: '12:00', end: '13:00', shift_id: 2 },
          { id: 7,  start: '13:00', end: '14:00', shift_id: 2 },
          { id: 8,  start: '14:00', end: '15:00', shift_id: 2 },
          { id: 9,  start: '15:00', end: '16:00', shift_id: 2 },
          { id: 10, start: '16:00', end: '17:00', shift_id: 2 },
          { id: 11, start: '17:00', end: '18:00', shift_id: 2 },
          { id: 12, start: '18:00', end: '19:00', shift_id: 3 },
          { id: 13, start: '19:00', end: '20:00', shift_id: 3 },
          { id: 14, start: '20:00', end: '21:00', shift_id: 3 },
          { id: 15, start: '21:00', end: '22:00', shift_id: 3 },
          { id: 16, start: '22:00', end: '23:00', shift_id: 3 },
          { id: 17, start: '23:00', end: '00:00', shift_id: 3 },
        ],
        data: {
          total: 0,
          items: []
        },
        colors: ['#66BB6A', '#81D4FA', '#0277BD', '#FFEB3B', '#FF5722'],
        brand_id: null,
        campuses: [],
        blocks: [],
        campus_ids: null,
        categoryAmbiences: [],
        ambienceTypes: [],
        workLoadTypes: [],
        statuses: [],
        min: 0,
        max: 500,
        students: [0, 500],
        capacity: [0, 500],
        errors: [],
        byPercent: false,
        days: [
          { id: 1, name: 'Domingo', initials: 'Domingo', visible: false },
          { id: 2, name: 'Segunda', initials: 'Segunda', visible: true },
          { id: 3, name: 'Terça',   initials: 'Terça', visible: true },
          { id: 4, name: 'Quarta',  initials: 'Quarta', visible: true },
          { id: 5, name: 'Quinta',  initials: 'Quinta', visible: true },
          { id: 6, name: 'Sexta',   initials: 'Sexta', visible: true },
          { id: 7, name: 'Sábado',  initials: 'Sábado', visible: true },
        ]
    }),

    watch: {

      campuses(val){
        
        if(val && val.length){
          this.campus_ids = val.map(i => i.id).join(',');
        }else{
          this.campus_ids = null;
        }
      },

    },
    
    methods: {
      ...mapActions('ambienceOccupation', ['ActionFindAmbienceBalance']),
              
      getData(){
      
        this.loading = true;
        this.data.total = 0;
        this.data.items = [];
        this.errors = [];

        const params = {
          campuses:           this.campuses.map(i => i.id),
          blocks:             this.blocks.map(i => i.id),
          categoryAmbiences:  this.categoryAmbiences.map(i => i.id),
          ambienceTypes:      this.ambienceTypes.map(i => i.id),
          workLoadTypes:      this.workLoadTypes.map(i => i.id),
          statuses:           this.statuses.map(i => i.id),
          capacity_min:       this.capacity[0],
          capacity_max:       this.capacity[1],
          students_min:       this.students[0],
          students_max:       this.students[1],
        };

        this.ActionFindAmbienceBalance(params)
            .then((res) => {
               this.data = res.data;
            }).catch(error => {
              this.errors = error.errors;
            }).finally(() => {
                this.loadingProgress = false;
                this.loading = false;
            })
      },

      getTotalItems(hour, day){
        
        let total = 0;
        let limit = 26;
        let keys = [];

        this.data.items.forEach(item => {

          let start = item.hour.start.substring(0, 5);
          let end = item.hour.end.substring(0, 5);
          let minutesStart = 60 - parseInt(start.substring(3));
          let minutesEnd = parseInt(end.substring(3));
          
          if(item.day_of_week_id == day.id && ((start >= hour.start && start < hour.end && minutesStart > limit) || (end > hour.start && end <= hour.end && minutesEnd > limit))){
              
            total++;  
            
            // let key = hour.start + '|' + hour.end + '|' + item.day_of_week_id + '|' + item.group_id;

            // if(!keys.includes(key)){
            //   total++;
            //   keys.push(key);
            // }
             
          }

        });

        return total;
        
      },

      getPercent(hour, day){
        
        if(this.data.total){
          return (this.getTotalItems(hour, day) / this.data.total) * 100;
        }else{
          return 100;
        }
      },

      getColor(value){

        if(value <= 25){
          return this.colors[0];
        }else if(value <= 50){
          return this.colors[1];
        }else if(value <= 75){
          return this.colors[2];
        }else if(value <= 100){
          return this.colors[3];
        }else{
          return this.colors[4];
        }

      }

  },

}
